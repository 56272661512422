<template>
  <div style="flex: 1; align-items: stretch" class="flex_c">
    <div
      class="flex_c"
      style="align-items: stretch"
      v-if="orderList.length > 0"
    >
      <OrderItem
        v-for="(order, index) in orderList"
        :key="index"
        :order="order"
      />
    </div>
    <div class="nodata flex_c" style="flex: 1" v-else>暂无订单</div>
  </div>
</template>

<script>
import axios from 'axios'
import OrderItem from './OrderItem.vue'
export default {
  inject: ['qiyuParams'],
  components: {
    OrderItem,
  },
  props: {
    orderStatus: String,
  },
  mounted() {
    window.onscroll = () => {
      if (
        this.getClientHeight() + this.getScrollTop() ===
          this.getScrollHeight() &&
        this.orderList.length % 10 === 0
      ) {
        console.log('加载')
        this.page++
        this.getOrders()
      }
    }
  },
  data() {
    return {
      page: 0,
      orderList: [],
    }
  },
  watch: {
    orderStatus: function() {
      this.page = 0
      this.orderList = []
      this.getOrders()
    },
  },
  created() {
    this.getOrders()
  },
  methods: {
    async getOrders() {
      console.log('订单状态', this.orderStatus)
      const { data } = await axios.get(
        `https://qiyukfapi.tarank.com/api/you-zan/you-zan-orders?appId=${this.qiyuParams.appId}&userOpenId=${this.qiyuParams.userOpenId}&status=${this.orderStatus}&page=${this.page}&size=10`
      )
      this.orderList = this.orderList.concat(data)
    },
    getScrollHeight() {
      return Math.max(
        document.body.scrollHeight,
        document.documentElement.scrollHeight
      )
    },
    getScrollTop() {
      var scrollTop = 0
      if (document.documentElement && document.documentElement.scrollTop) {
        scrollTop = document.documentElement.scrollTop
      } else if (document.body) {
        scrollTop = document.body.scrollTop
      }
      return scrollTop
    },
    getClientHeight() {
      var clientHeight = 0
      if (document.body.clientHeight && document.documentElement.clientHeight) {
        clientHeight = Math.min(
          document.body.clientHeight,
          document.documentElement.clientHeight
        )
      } else {
        clientHeight = Math.max(
          document.body.clientHeight,
          document.documentElement.clientHeight
        )
      }
      return clientHeight
    },
  },
}
</script>

<style lang="less" scoped>
.nodata {
  justify-content: center;
}
</style>
