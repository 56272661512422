<template>
  <div id="app" class="flex_c">
    <User @onStatusChange="onStatusChange" />
    <Orders :order-status="status" />
  </div>
</template>

<script>
import User from './components/User.vue'
import Orders from './components/Orders'

const getQuery = () => {
  const search = window.location.search.slice(1)
  if (search) {
    const query = search.split('&').reduce((p, n) => {
      const kv = n.split('=')
      p[kv[0]] = decodeURIComponent(kv[1])
      return p
    }, {})
    console.log(query)
    return query
  } else {
    return null
  }
}
export default {
  name: 'App',
  components: {
    User,
    Orders,
  },
  provide: {
    qiyuParams: getQuery(),
  },
  data() {
    return {
      status: '',
    }
  },
  methods: {
    onStatusChange(val) {
      this.status = val
    },
  },
}
</script>

<style lang="less">
* {
  margin: 0;
  padding: 0;
  font-size: 12px;
  color: #666;
}
.flex_r {
  display: flex;
  align-items: center;
}
.flex_c {
  display: flex;
  flex-direction: column;
  align-items: center;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  max-width: 445px;
  min-width: 445px;
  width: 100%;
  padding: 14px 10px;
  max-height: 100vh;
  min-height: 100vh;
  align-items: stretch;
  box-sizing: border-box;
}
</style>
