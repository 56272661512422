<template>
  <div>
    <div class="fake_user"></div>
    <div class="user">
      <div class="flex_r">
        <div class="img_wp"><img :src="info.avatar" alt="" /></div>
        <div class="name">{{ info.nickName || '暂无' }}</div>
      </div>
      <div class="value_wp">
        <div class="flex_r" style="margin-bottom: 8px; margin-top: 8px">
          <div class="label">昵称：</div>
          <div class="value">{{ info.nickName || '暂无' }}</div>
        </div>
        <div class="flex_r" style="margin-bottom: 8px">
          <div class="label">手机号：</div>
          <div class="value">{{ info.mobile || '暂无' }}</div>
        </div>
        <div class="flex_r">
          <div class="label">来自店铺：</div>
          <div class="value">{{ info.shopName || '暂无' }}</div>
        </div>
      </div>
      <div class="selector flex_r">
        订单状态：
        <select name="order" id="orderSelect" v-model="selectedStatus">
          <option
            v-for="(status, index) in statusCnEnums"
            :key="status"
            :value="statusEnums[index]"
            >{{ status }}</option
          >
        </select>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  inject: ['qiyuParams'],
  created() {
    this.getUser()
  },
  data() {
    return {
      info: {},
      selectedStatus: '',
      statusEnums: [
        '',
        'WAIT_BUYER_PAY',
        'WAIT_SELLER_SEND_GOODS',
        'WAIT_BUYER_CONFIRM_GOODS',
        'TRADE_SUCCESS',
        'TRADE_CLOSE',
        'TRADE_REFUND',
      ],
      statusCnEnums: [
        '全部订单',
        '待付款',
        '待发货',
        '等待买家确认',
        '订单完成',
        '订单关闭',
        '退款中',
      ],
    }
  },
  watch: {
    selectedStatus: function(val) {
      this.$emit('onStatusChange', val)
    },
  },
  methods: {
    getUser: async function() {
      const { data, headers } = await axios.get(
        `https://qiyukfapi.tarank.com/api/you-zan/you-zan-user-info?appId=${this.qiyuParams.appId}&userOpenId=${this.qiyuParams.userOpenId}`
      )
      this.info = data.latestInfo
      this.info.mobile = data.userList[0]?.mobileInfo?.mobile
      this.info.shopName = decodeURIComponent(headers['you-zan-shop-name'])
    },
  },
}
</script>

<style lang="less" scoped>
.user {
  width: 445px;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #fff;
  padding: 14px 10px 0 10px;
}
.fake_user {
  height: 147px;
  background-color: #ccc;
}
.img_wp {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #ddd;
  margin-right: 10px;
  overflow: hidden;
  img {
    width: 100%;
    display: block;
  }
}
.name {
  font-size: 12px;
  color: #333;
}
.label {
  font-size: 12px;
  color: #666;
  width: 60px;
  white-space: nowrap;
  text-align: right;
}
.value {
  font-size: 12px;
  color: #333;
}
.selector {
  height: 36px;
  border-top: 1px solid #f4f4f4;
  margin-top: 10px;
  border-bottom: 1px solid #f4f4f4;
}
.list {
  flex: 1;
  overflow: scroll;
}
</style>
