<template>
  <div class="order_item" style="width: 100%">
    <div class="order_info flex_r">订单编号：{{ order.orderInfo.tid }}</div>
    <div class="order_info flex_r">
      下单时间：{{ new Date(order.orderInfo.created) | wlTime }}
      <span>{{ order.orderInfo.statusStr }}</span>
    </div>
    <div class="order_info flex_r">
      收货姓名：{{ order.addressInfo.receiverName }}
    </div>
    <div class="order_info flex_r">
      收货电话：{{ order.addressInfo.receiverTel }}
    </div>
    <div class="order_info flex_r">
      收货地址：{{ order.addressInfo | addressFilter }}
    </div>
    <div class="card flex_r" v-for="(sub, index) in order.orders" :key="index">
      <div class="img_wp">
        <img :src="sub.picPath" />
      </div>
      <div class="flex_c content">
        <div class="name">{{ sub.title }}</div>
        <div class="sku">{{ sub.skuPropertiesName | skuFilter }}</div>
      </div>
    </div>
    <div class="flex_r price">
      共{{ order.orders.length }}件商品，实付款￥{{ order.payInfo.payment }}
    </div>
  </div>
</template>

<script>
function formatTime(date) {
  if (typeof date == 'string') {
    date = new Date(date)
  }
  const year = date.getFullYear()
  const month = date.getMonth() + 1
  const day = date.getDate()

  const hour = date.getHours()
  const minute = date.getMinutes()
  const second = date.getSeconds()

  const t1 = [year, month, day].map(formatNumber).join('-')
  const t2 = [hour, minute, second].map(formatNumber).join(':')

  return { date: t1, time: t2 }
  // return `${t1} ${t2}`
}
function formatNumber(n) {
  const str = n.toString()
  return str[1] ? str : `0${str}`
}
export default {
  filters: {
    wlTime(date) {
      let timeObj = formatTime(date)
      return (
        timeObj.date +
        ' ' +
        timeObj.time
          .split(':')
          .slice(0, 2)
          .join(':')
      )
    },
    addressFilter(obj) {
      const {
        deliveryProvince,
        deliveryCity,
        deliveryDistrict,
        deliveryAddress,
      } = obj
      return deliveryProvince === deliveryCity
        ? [deliveryCity, deliveryDistrict, deliveryAddress].join(' ')
        : [
            deliveryProvince,
            deliveryCity,
            deliveryDistrict,
            deliveryAddress,
          ].join(' ')
    },
    skuFilter(sku) {
      const arr = JSON.parse(sku)
      return arr.reduce((p, n) => p + n.v, '')
    },
  },
  props: {
    order: Object,
  },
  methods: {},
}
</script>

<style lang="less" scoped>
.order_item {
  padding: 10px 0;
  border-top: 1px solid #f4f4f4;
}
.order_info {
  margin-bottom: 5px;
  text-align: left;
  justify-content: space-between;
  span {
    color: #0091ff;
  }
}
.card {
  align-items: stretch;
  margin-bottom: 5px;
  .img_wp {
    width: 60px;
    height: 60px;
    background-color: #ddd;
    margin-right: 5px;
    flex-shrink: 0;
    img {
      width: 100%;
      display: block;
    }
  }
  .content {
    flex: 1;
    align-items: flex-start;
    justify-content: space-between;
    .name {
      color: #333;
      text-align: left;
    }
  }
}
.price {
  margin-top: 5px;
  justify-content: flex-end;
}
</style>
